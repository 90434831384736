import * as React from 'react';
import * as styles from './footerV.module.scss';
import {FooterText} from './footerText';




export class Footer extends React.Component {



    render() {
        return (
        	
            <div className={styles.footer}>
            	<FooterText/>
            
    		</div>
        );
    }
}