import * as React from 'react';
import PropTypes from "prop-types";
import * as styles from './contentPage.module.scss';
import {Page} from "../../common/page";
import {Header} from "../../common/header";
import {Footer} from './footerV';



export class ContentPage extends React.Component {

    

    render() {
        return (
            <Page>
            	<Header activePage={this.props.activePage}/>

                <div className={styles.content}>

                {this.props.children ? this.props.children : <div className={styles.ccontent}/>}

                </div>
              
                <div className={styles.footer}>
                    <Footer/>
                </div>         

            </Page>
        );
    }
}

ContentPage.propTypes = {

    activePage: PropTypes.number.isRequired,


};
