import React from "react";
import PropTypes from "prop-types";
import { Redirect } from 'react-router';
import {ContentPage} from "../contentPage";
import {Pages, devURL} from '../../../config/pages';

import * as styles from './elnoksegLoginPage.module.scss';




export class ElnoksegLoginPage extends React.Component {
	constructor(props) {
        super(props);

        this.state = { 
            username: '',
            password: '',
        };

        this.setUsername = this.setUsername.bind(this);
        this.setPassword = this.setPassword.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    setUsername(e) {
    	this.setState({username: e.target.value});
    }

    setPassword(e) {
    	this.setState({password: e.target.value});
    }

  

    handleSubmit(e) {
    	e.preventDefault();
    	fetch(devURL + '/php/auth.php',
        {
            method: 'POST',
            headers: 
            {
                
            },
            body: JSON.stringify(
            {
                username: this.state.username,
 
                password: this.state.password
            })
 
        })
        .then((response) => response.json())
        .then((responseJson) =>
        {
            this.props.setToken(responseJson.jwt);
            this.props.authenticate();
        }).catch((error) =>
        {
            console.error(error);
        });
    }


    render() {

        if (this.props.redirect) {
                return <Redirect to={Pages.elnokseg.home}/>
            }

        return (
            <ContentPage activePage={-1}>
            <div className={styles.container}>
                
	           <form className={styles.form} onSubmit={this.handleSubmit} >
	  				<div className={styles.inputRow}>Felhasználónév: 
	  					<input onChange={this.setUsername} value={this.state.username} className={styles.inputBox} type="text" name="felhasználónév"/>
	  				</div>
	  				<div className={styles.inputRow}>Jelszó: 
	  					<input onChange={this.setPassword} value={this.state.password} className={styles.inputBox} type="password" name="jelszó"/>
	  				</div>
	  				<input className={styles.submit} type="submit" value="Bejelentkezés"/>
				</form>

			</div>

            </ContentPage>
        );
    }
}

ElnoksegLoginPage.propTypes = {
    redirect: PropTypes.bool.isRequired,
    authenticate: PropTypes.func.isRequired,
    setToken: PropTypes.func.isRequired,


    
};
