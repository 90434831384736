import React from "react";
import PropTypes from "prop-types";
import {Title} from './title';
import Menu from './menu';
import * as styles from './header.module.scss';
import styled from "styled-components";

const Container = styled.div`
    height: ${props => props.height};
`;



export class Header extends React.Component {
     constructor(props) {
        super(props);
        this.title = React.createRef();

        this.state = { 
            menuHeight: 0,
            headerHeight: 0,
        };

        this.setHeaderHeight = this.setHeaderHeight.bind(this);
        this.setMenuHeight = this.setMenuHeight.bind(this);

    }

    componentDidMount() {
        setTimeout(() => this.setState({ headerHeight: `${this.title.current.getBoundingClientRect().height}px` }), 0);

    }


     setHeaderHeight() {
            const top = Math.max(this.title.current.getBoundingClientRect().height, this.state.menuHeight);
            this.setState({headerHeight: `${top}px`});
        
    } 

    setMenuHeight(h) {
        this.setState(
            {menuHeight: h}, 
            () => {this.setHeaderHeight();}
        );

    }

    render() {
        return (

            <div>
                <Container className={styles.spaceHolder} height={this.state.headerHeight}/>

            	<Container className={styles.container} height={this.state.headerHeight}>
                    <div className={styles.headerRow}>
                        <Title ref={this.title} onHomepage={false}/>
                        <Menu setMenuHeight={this.setMenuHeight} onHomepage={false} activePage={this.props.activePage}/>
                    </div>
                    <div className={styles.logoRow}>
                        <div className={styles.logo}/>

                    </div>
                </Container>
            </div>

        );
    }
}


Header.propTypes = {

    activePage: PropTypes.number.isRequired,


};
